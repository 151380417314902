@import url("//fonts.googleapis.com/css?family=Oswald:300,400,700");

.txt-white
{
	color: #fff;
}

.txt-black
{
	color: #333;
}

.icon
{
	box-sizing: border-box;
	display: inline-block;
	width: 20px;
	height: 17px;
	border-radius: 3px;
	border: solid 1px #666;
	background-color: #ccc;
	color: #fff;
	font-family: oswald, sans-serif;
	font-size: 11px;
	font-weight: normal;
	text-align: center;
	line-height: 14px;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

a.icon:link, a.icon:visited
{
	color: #fff;
}

a.icon:hover
{
	text-decoration: none;
}

.icon-7inch, .icon-10inch, .icon-12inch
{
	font-weight: bold;
	background-color: #ff6;
}

.icon-LP
{
	background-color: #00f;
}

.icon-CD
{
	background-color: #f00;
}

.icon-DVD
{
	background-color: #f0f;
}

.icon-mixcd
{
	background-color: #960;
}

.icon-tape
{
	background-color: #690;
}

.icon-video
{
	background-color: #666;
}

.icon-book
{
	font-size: 9px;
	background-color: #360;
}

.icon-cloth
{
	padding-top: 2px;
	background-color: #f90;
}

.icon-acc
{
	background-color: #903;
}

.icon-ticket
{
	padding-top: 2px;
	background-color: #f9c;
}

.icon-format-1, .icon-format-2, .icon-format-3
{
    font-weight: bold;
    background-color: #ff6;
}

.icon-format-1:before
{
    content: "7\"";
    color: #000;
}

.icon-format-2:before
{
    content: "10\"";
    color: #000;
}

.icon-format-3:before
{
    content: "12\"";
    color: #000;
}

.icon-format-4
{
    background-color: #00f;
}

.icon-format-4:before
{
    content: "LP";
}

.icon-format-5
{
    background-color: #f00;
}

.icon-format-5:before
{
    content: "CD";
}

.icon-format-6
{
    background-color: #903;
}

.icon-format-6:before
{
    content: "ACC";
}

.icon-format-7
{
    background-color: #666;
}

.icon-format-7:before
{
    content: "VHS";
}

.icon-format-8
{
    font-size: 9px;
    background-color: #360;
}

.icon-format-8:before
{
    content: "Book";
}

.icon-format-9, .icon-format-12
{
    background-color: #960;
}

.icon-format-9:before, .icon-format-12:before
{
    content: "MIX";
}

.icon-format-11
{
    background-color: #690;
}

.icon-format-11:before
{
    content: "CSS";
}

.icon-format-13
{
    background-color: #f0f;
}

.icon-format-13:before
{
    content: "DVD";
}

.icon-format-14
{
    background-color: #f90;
}

.icon-format-14:before
{
    font-family: FontAwesome;
    content: "\f007";
}

.icon-format-15
{
    padding-top: 1px;
    background-color: #f9c;
}

.icon-format-15:before
{
    font-family: FontAwesome;
    content: "\f145";
}

.icon-format-10
{
    background-color: #39c;
}

.icon-format-10:before
{
    content: "ETC";
}