@media print {
  a[href]:after {
    content: "" !important;
  }
  abbr[title]:after {
    content: "" !important;
  }
}

body {
  margin: 0 0 0 0;
}

th {
  white-space: nowrap;
}

.protected {
  pointer-events: none;
}

.wait {
  cursor: wait;
}

#page_main {
  margin-left: 225px;
}

.min_width {
  min-width: 1000px !important;
}

.fixed_width {
  min-width: 1000px !important;
  margin: 0px auto;
  padding: 0px 15px;
}

.display-table {
  display: table;
}

.vcenter {
  display: table-cell;
  vertical-align: middle !important;
}

.vtop {
  vertical-align: top !important;
}

@media (max-width: 1300px) {
  .fixed_width {
    max-width: 1100px;
  }
}

@media (min-width: 1300px) {
  .fixed_width {
    max-width: 1400px;
  }
}

.control-label {
  white-space: nowrap;
}

#image_overlay {
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}

#image_overlay .slide_show {
  height: 100%;
  margin: auto;
}

#image_overlay .slide_show td {
  padding: 10px;
}

#image_overlay .slide_show .image img {
  max-width: 100%;
  height: auto;
  display: block;
}

#header_stripe {
  height: 6px;
  /*border-top: solid 1px #fff;*/
  min-width: 1000px !important;
}

#header_tabs_bg {
  background-color: #666;
  background-image: url(/common_img/tab_bg_2.gif?151204-1252);
  background-repeat: repeat-x;
}

#header_tabs {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  text-align: center;
}

#header_tabs li {
  float: none;
  margin-top: 7px;
  margin-bottom: 0px;
  display: inline-block;
  position: relative;
}

#header_tabs li a {
  margin-right: 2px;
  line-height: 1.42857143;
  /*border-bottom: 1px solid #fff;*/
  border-radius: 4px 4px 0 0;
  font-size: 11px;
  padding: 4px 4px 4px;
  background-color: #ccc;
  min-width: 60px;
  text-align: center;
  position: relative;
  display: block;
}

#header_tabs > li > a:hover,
#header_tabs > li > a:link,
#header_tabs > li > a:visited {
  color: #666;
  text-decoration: none;
}

#header_tabs > li.active > a,
#header_tabs > li > a:hover {
  color: #333;
  background-color: #eee;
  border-bottom-color: transparent;
}

#multi_search_inputs {
  width: 80% !important;
  float: left;
}

.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 3px;
}

.fit {
  display: inline-block;
  padding: 15px 30px;
}

.header_buttons {
  float: right;
}

.navbar {
  border: none;
  border-radius: 0;
}

#search_bar {
  background-color: #999;
  text-align: center;
}

#search_bar select {
  margin: 2px 1px;
  border-radius: 2px;
}

#page_left a,
#page_left a:link,
#page_left a:visited {
  color: #555;
}

#top_shipping_information {
  margin-top: 5px;
  margin-bottom: 20px;
}

#top_shipping_information .panel-body {
  padding-top: 0;
}

#top_shipping_information h3 {
  font-size: 20px;
  line-height: 1.0;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

#top_shipping_information p {
  font-size: 12px;
  line-height: 1.1;
}

.side_bar {
  float: left;
  width: 210px;
  padding: 0;
  font-size: 13px;
}

.special_banners {
  margin: 5px 0;
  /*border: solid #ccc 1px;*/
  background-color: #eee;
  padding: 2px 0;
}

.special_banners .banner {
  display: block;
  margin: 2px auto;
}

.side_bar .help {
  margin-right: 5px;
}

.side_bar .help i {
  line-height: 16px;
}

.side_bar .section {
  margin-bottom: 46px;
}

.side_bar .show_all {
  padding: 0;
  margin-top: 23px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
}

.side_bar .section ul {
  list-style: none;
  line-height: 166%;
  padding-left: 0;
}

.side_bar .section li {
  margin: 4px 0;
  padding-left: 5px;
}

.side_bar .section .release,
.side_bar .section .link {
  margin-top: 19px;
}

.side_bar .section .link.to_top {
  margin-bottom: 30px;
}

.side_bar .section .release {
  font-weight: bold;
}

.side_bar .section .header {
  font-weight: bold;
  padding: 0;
  border-bottom: 1px solid #ccc;
  margin: 19px 0;
}

.side_bar .section .header .header_help {
  float: right;
}

.side_bar .section li.genre_category_header {
  margin-top: 19px;
  padding-top: 23px;
  border-top: solid 4px #eee;
}

.info.help i {
  font-size: 14px !important;
}

.checkout #content .row {
  margin-bottom: 8px;
}

#indicators .row {
  margin-bottom: 8px;
}

.search_form {
  background-color: #eee;
  text-align: center;
  margin: 0;
  padding-top: 1px;
}

.search_form [class*="col-"] {
  padding-left: 0;
  padding-right: 1px;
}

.search_form .tops li {
  font-size: 12px;
  height: 16px;
}

#simple_search_form .form-group {
  margin-bottom: 0px;
  text-align: right;
}

#simple_search_form a {
  font-size: 12px;
  line-height: 14px;
}

#simple_search_inputs {
  float: left;
  margin-top: 14px;
  margin-bottom: 0;
  width: 50%;
  text-align: left;
}

#input_keyword {
  width: 100%;
}

.header_buttons {
  float: right;
  margin: 17px 0;
}

.header_buttons .text_link {
  color: #666;
  margin: 0 10px;
}

.header_buttons h5 {
  margin: 0;
  padding: 0;
  display: inline-block;
}

#header_menu a,
#header_menu a:visited,
#header_menu a:link {
  color: #666;
}

#header_menu ul {
  padding-left: 0;
}

#header_menu li {
  list-style: none;
  margin-bottom: 15px;
}

#header_menu dt {
  margin-bottom: 6px;
}

#header_menu dd {
  margin-bottom: 18px;
}

.navbar-primary .navbar {
  border-bottom: none;
}

.navbar-primary .navbar .nav.pull-right {
  margin-left: 10px;
  margin-right: 0;
}

.navbar-xs .navbar-primary .navbar {
  min-height: 28px;
  height: 28px;
}

.navbar-xs .navbar-primary .navbar .navbar-brand {
  padding: 0 12px;
  font-size: 16px;
  line-height: 28px;
}

.navbar-xs .navbar-primary .navbar .navbar-nav > li > a {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 28px;
}

.navbar-sm .navbar-primary .navbar {
  min-height: 40px;
  height: 40px;
}

.navbar-sm .navbar-primary .navbar .navbar-brand {
  padding: 0 12px;
  font-size: 16px;
  line-height: 40px;
}

.navbar-sm .navbar-primary .navbar .navbar-nav > li > a {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 40px;
}

.breadcrumb {
  background-color: #fff;
  margin: 21px 0 15px;
  padding: 2px;
}

.dropdown-panels .panel {
  margin: 4px;
}

.dropdown-panels .panel-heading {
  text-align: center;
}

.dropdown-panels div {
  white-space: nowrap;
}

.dropdown-panels td {
  vertical-align: top;
}

.dropdown-panels dl {
  margin-bottom: 0;
}

#itemlist_header {
  margin: 5px 0 5px;
}

#search_description {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
}

#search_description a.clear_narrow_down:link,
#search_description a.clear_narrow_down:visited {
  margin-left: 15px;
}

#ranking_menu {
  padding: 10px 10px 0 0;
  font-size: 14px;
  text-align: center;
}

#ranking_menu h2 {
  text-align: center;
  border-radius: 12px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #777;
  padding: 0 4px;
  width: auto;
  margin: 10px 0;
}

#ranking_genre_selector {
  margin: 25px 0;
}

#ranking_genre_selector span {
  margin-right: 20px;
}

#ranking_genre_selector a.active {
  text-decoration: underline;
}

#ranking_genre_selector ul#genre_main {
  width: 600px;
}

#ranking_genre_selector ul#genre_main li {
  width: 290px;
  float: left;
}

#ranking_genre_selector ul#genre_main li:nth-child(2n+3) {
  clear: left;
  float: left;
}

#ranking_genre_selector ul#genre_main li a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#itemList {
  margin-bottom: 34px;
}

#itemList.genre {
  margin-bottom: 0;
}

#itemList.catalog .description, #itemList.vintage .description, #itemList.genre_top .description, #itemList.format_top .description, #itemList.ranking .description {
  position: relative;
  min-height: 135px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-bottom: solid 1px #ccc;
  font-size: 14px;
}

#itemList.catalog .description .image, #itemList.vintage .description .image, #itemList.genre_top .description .image, #itemList.format_top .description .image, #itemList.ranking .description .image {
  float: left;
  width: 120px;
}

#itemList.catalog .description .image img, #itemList.vintage .description .image img, #itemList.genre_top .description .image img, #itemList.format_top .description .image img, #itemList.ranking .description .image img {
  width: 120px;
  height: 120px;
}

#itemList.catalog .description .info, #itemList.vintage .description .info, #itemList.genre_top .description .info, #itemList.format_top .description .info, #itemList.ranking .description .info {
  margin-left: 125px;
}

#itemList.catalog .description p, #itemList.vintage .description p, #itemList.genre_top .description p, #itemList.format_top .description p, #itemList.ranking .description p {
  margin-bottom: 4px;
  font-size: 85%;
  line-height: 16px;
}

#itemList.catalog .description .buttons, #itemList.vintage .description .buttons, #itemList.genre_top .description .buttons, #itemList.format_top .description .buttons, #itemList.ranking .description .buttons {
  margin-bottom: 5px;
}

#itemList.catalog .description .buttons table tr td, #itemList.vintage .description .buttons table tr td, #itemList.genre_top .description .buttons table tr td, #itemList.format_top .description .buttons table tr td, #itemList.ranking .description .buttons table tr td {
  padding-right: 2px;
}

#itemList.catalog .description .form-control, #itemList.vintage .description .form-control, #itemList.genre_top .description .form-control, #itemList.format_top .description .form-control, #itemList.ranking .description .form-control {
  width: 34px;
  margin-right: 1px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

#itemList.catalog .description a:hover, #itemList.vintage .description a:hover, #itemList.genre_top .description a:hover, #itemList.format_top .description a:hover, #itemList.ranking .description a:hover {
  color: #0ce;
  text-decoration: none;
}

#itemList.catalog .description .genre.top, #itemList.vintage .description .genre.top, #itemList.genre_top .description .genre.top, #itemList.format_top .description .genre.top, #itemList.ranking .description .genre.top {
  font-weight: normal;
  font-size: 80%;
}

#itemList.catalog .description .buy_buttons, #itemList.vintage .description .buy_buttons, #itemList.genre_top .description .buy_buttons, #itemList.format_top .description .buy_buttons, #itemList.ranking .description .buy_buttons {
  margin-left: auto;
  margin-right: 0;
}

#itemList.catalog .description .genre_search, #itemList.vintage .description .genre_search, #itemList.genre_top .description .genre_search, #itemList.format_top .description .genre_search, #itemList.ranking .description .genre_search {
  color: #333;
}

#itemList.catalog .description .genre_top, #itemList.vintage .description .genre_top, #itemList.genre_top .description .genre_top, #itemList.format_top .description .genre_top, #itemList.ranking .description .genre_top {
  color: #666;
}

#itemList.catalog .description .buy_button, #itemList.vintage .description .buy_button, #itemList.genre_top .description .buy_button, #itemList.format_top .description .buy_button, #itemList.ranking .description .buy_button {
  float: right;
  text-align: right;
  white-space: nowrap;
  min-width: 126px;
}

#itemList.catalog .description .buy_button a.btn:hover, #itemList.vintage .description .buy_button a.btn:hover, #itemList.genre_top .description .buy_button a.btn:hover, #itemList.format_top .description .buy_button a.btn:hover, #itemList.ranking .description .buy_button a.btn:hover {
  color: #fff;
}

#itemList.catalog .description .buy_button p, #itemList.vintage .description .buy_button p, #itemList.genre_top .description .buy_button p, #itemList.format_top .description .buy_button p, #itemList.ranking .description .buy_button p {
  margin-top: 5px;
}

#itemList.discography .description {
  min-height: none !important;
  border-bottom: solid #ccc 1px;
}

#itemList.discography .description .image {
  float: none;
  width: 60px;
}

#itemList.discography .description .image img {
  width: 60px;
  height: 60px;
}

#itemList.discography .description .chk-inventory {
  font-size: 12px;
}

#itemList.discography .description table {
  margin: 5px 0;
}

#itemList.discography .description table tr td.info {
  font-size: 12px;
  padding-left: 5px;
  text-align: left;
  vertical-align: middle;
}

#itemList.discography .description table tr td.info.format {
  width: 32px;
}

#itemList.discography .description table tr td.info.artist {
  width: 20%;
}

#itemList.discography .description table tr td.info.label_name {
  width: 20%;
}

#itemList.discography .description table tr td.info.catalog_number {
  width: 80px;
}

#itemList.discography .description table tr td.info.year {
  width: 35px;
}

#itemList.discography .description table tr td.info.buttons {
  text-align: right;
  width: 1%;
}

#itemList.discography .description table tr td.info.buy_buttons {
  text-align: right;
  white-space: nowrap;
  width: 42px;
  padding-left: 0;
}

#itemList.discography .description table tr td.info.buy_buttons .dropdown-toggle {
  padding-right: 0;
  padding-left: 4px;
}

#itemList.discography .description table tr td.info.buy_buttons .label {
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 4px 3px;
  font-size: 9px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
}

#itemList.discography .description table tr td.info.buy_buttons span.status_str {
  display: none;
}

#itemList.discography .description table tr td.info.buy_buttons span.status_icon {
  display: inline;
}

#itemList.discography .description table tr td.info .listen {
  min-width: 22px;
}

#itemList .rank {
  font-family: Lato, sans-serif;
  position: absolute;
  top: 4px;
  left: 4px;
  height: 21px;
  padding: 0 6px;
  text-align: center;
  line-height: 20px;
  background-color: #3baee7;
  border-radius: 3px;
  color: #fff;
  z-index: 1;
}

#itemList.ranking {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.description .artist {
  font-weight: bold;
  color: #333;
}

.description .title {
  color: #000;
}

.description .label_name {
  color: #00c;
}

.description .label_name:last-child {
  margin-right: 5px;
}

.description .rhythm {
  color: #f00;
}

.description span.info {
  color: #0c0;
}

.description .producer {
  color: #900;
}

.description .price {
  margin-right: 10px;
}

.description .rhythm {
  margin-right: 5px;
}

.description .review {
  color: #666;
  padding-left: -122px;
}

#description_main .buy_buttons table tr td {
  padding-right: 2px;
}

#description_main .buy_buttons .form-control {
  width: 34px;
  margin-right: 1px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

#description_main .buy_buttons span.status_str {
  display: inline;
}

#description_main .buy_buttons span.status_icon {
  display: none;
}

#description_main .share_buttons {
  padding-top: 17px;
}

#description_main .share_buttons #reviews {
  max-width: 600px;
}

#description_main .share_buttons #reviews .review {
  margin-bottom: 23px;
}

#description_main .share_buttons #reviews .review p {
  margin-bottom: 1em;
}

.buy_buttons .form-control.input-xs {
  width: 22px;
  text-align: center;
}

.item_description_image {
  position: relative;
  float: left;
  width: 180px;
  height: 282px;
  margin-top: 5px;
  margin-right: 5px;
}

.item_description_image img.item {
  width: 180px;
  height: 180px;
}

.item_description_image table {
  margin: auto;
}

.item_description_image p {
  width: 170px;
  margin: 0px auto;
  text-align: center;
  font-size: 12px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item_description_image .artist {
  color: #000;
}

.item_description_image .title {
  font-weight: bold;
  color: #333;
}

.item_description_image .label_name {
  color: #66c;
}

.item_description_image .rr-btn-blank {
  width: 22px;
  height: 17px;
}

.item_description_image .rr-btn-blank:after {
  content: '\00a0' !important;
}

.item_description_image a:hover {
  color: #0ce;
  text-decoration: none;
}

.item_description_image .item_image {
  position: relative;
  margin-bottom: 2px;
}

.item_description_image .item_image .rating {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #dd0;
}

.discography .item_description_image {
  width: 120px;
  height: 200px;
}

.discography .item_description_image img.item {
  width: 120px;
  height: 120px;
}

.discography .item_description_image p {
  width: 110px;
  font-size: 10px;
}

.discography .item_description_image .buy_buttons {
  text-align: center;
  margin: 0 auto;
  white-space: nowrap;
  padding-left: 0;
}

.discography .item_description_image .buy_buttons .dropdown-toggle {
  margin: 0;
  padding: 0;
  border-width: 0;
  height: 18px;
}

.discography .item_description_image .buy_buttons .caret {
  margin: 0;
}

.discography .item_description_image .buy_buttons .label {
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 4px 3px;
  font-size: 9px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
}

.discography .item_description_image .buy_buttons span.status_str {
  display: none;
}

.discography .item_description_image .buy_buttons span.status_icon {
  display: inline;
}

#browse_history {
  margin-left: -10px;
  margin-right: -10px;
}

#browse_history .item {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 0 15px;
}

#browse_history .item img {
  max-width: 120px;
  max-height: 120px;
}

.btn-play:not(.rr-btn-listen) {
  margin-right: 10px;
}

#description_main .btn-play {
  margin-right: 0;
  margin-left: 1px;
}

#second_crumb {
  margin-top: 15px;
  margin-bottom: 15px;
}

#content_menu {
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
}

#content_menu .section {
  margin-bottom: 20px;
  padding-top: 15px;
}

#content_menu .section table {
  margin: 0 auto;
}

#content_menu .section .header {
  width: 100%;
  border-top: #999 solid 1px;
  border-bottom: #999 solid 1px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.image_selector {
  position: relative;
}

.image_selector .thumbnails {
  padding: 5px;
  background-color: #ccc;
}

.image_selector .btn-thumbnail {
  display: block;
  float: left;
  width: 60px;
  margin: 5px;
}

.image_selector .magnify {
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #999;
  background-color: rgba(255, 255, 255, 0.75);
}

.image_selector .btn-thumbnail:hover,
.image_selector .magnify:hover {
  cursor: pointer;
}

#help_content {
  width: 80%;
  margin: 0 auto;
}

#help_content dd {
  margin-left: 4em;
}

#top_center {
  float: left;
  width: 72%;
  font-size: 80%;
  margin-bottom: 23px;
}

#top_center .page-header {
  margin-top: 15px;
  margin-bottom: 0;
}

#top_center .page-header h4 {
  margin: 5px 5px 0 0;
}

#top_center .header h4 {
  margin-bottom: 23px;
}

#top_center .header h5 {
  border-bottom: solid 1px #ddd;
  margin-top: 13px;
  margin-bottom: 13px;
  padding-bottom: 23px;
  font-size: 105%;
}

#browse_history {
  margin-top: 13px;
  margin-bottom: 23px;
}

#top_center .item {
  position: relative;
}

#top_center .item p {
  margin: 0.3em 0 0;
  line-height: 125%;
}

#top_center .item .description {
  margin-bottom: 20px;
}

#top_center .item_info p {
  font-size: 83%;
}

#top_center .item .icon,
#top_center .item .rr-btn {
  margin-right: 4px;
}

#top_center .item .rank, #top_right .item .rank {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  color: #fff;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  background-color: #00f;
}

#top_center div.row.tabs {
  margin-top: 15px;
  margin-bottom: 15px;
}

#top_center .genre_header {
  padding: 0;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto 5px;
}

#top_center .genre_header a.genre {
  color: #337ab7;
  font-weight: normal;
  font-size: 14px;
}

#top_center .pagination {
  margin-top: 13px;
}

#top_center .section {
  border-bottom: solid 1px #ddd;
  padding-bottom: 3px;
  margin-bottom: 23px;
}

#top_center .separator {
  border-top: solid 1px #ddd;
  margin-bottom: 36px;
}

#top_right {
  float: left;
  width: 28%;
  font-size: .75em;
  line-height: 1.5em;
}

#top_right .section {
  margin-bottom: 36px;
}

#top_right .item {
  border-bottom: solid 1px #ddd;
  padding-bottom: 15px;
  margin: 10px 0 5px 0;
}

#top_right .item.site {
  padding-bottom: 23px;
}

#top_right .image {
  position: relative;
  float: left;
  width: 32%;
}

#top_right .image img {
  margin: 0 auto;
}

#top_right .logo {
  float: left;
  width: 78px;
  margin-right: 4px;
}

#top_right .body {
  float: right;
  width: 68%;
  padding-left: 5px;
}

#top_right .item p {
  margin: 0.1em 0;
}

#top_right a:not(.rr-btn):not(.more):not(.genre):not(.btn) {
  color: #555;
}

#top_right .ranking.item {
  padding-bottom: 0;
}

#top_right a.genre {
  font-weight: normal;
}

#top_right .buttons {
  margin: 10px 0;
}

#top_right .item .rank {
  width: 16px;
  height: 16px;
  border-radius: 9px;
  line-height: 16px;
}

#top_right ol {
  counter-reset: rank;
  list-style: none;
  padding-bottom: 0;
  padding-left: 0;
}

#top_right ol li {
  margin: 2px 0;
}

#top_right ol li:before {
  display: inline-block;
  counter-increment: rank;
  content: counter(rank);
  color: #fff;
  font-weight: bold;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-right: 6px;
  background-color: #00f;
  border-radius: 9px;
}

#top_right .more {
  display: inline-block;
  margin-top: 18px;
  margin-bottom: 0;
  line-height: 1em;
  padding: 0;
}

#top_right .header h4, #top_right .header h5 {
  border-bottom: solid 1px #ddd;
  margin-bottom: 13px;
}

#top_right .special_banners {
  margin-bottom: 13px;
}

.page-header h1 a, .page-header h2 a, .page-header h3 a, .page-header h4 a {
  color: #555;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div#header {
  margin: 0;
}

#site_title {
  color: #333;
  margin: 0 0 0 20px;
  font-style: normal;
  font-size: 32px;
  font-family: Lato, sans-serif;
  font-weight: 300;
}

#site_title a:hover {
  text-decoration: none;
}

#site_caption {
  margin: 0 0 5px;
  font-size: 11px;
  font-weight: normal;
  line-height: 11px;
}

#header_username {
  margin: -8px 0 -8px 0;
  color: #fff;
  font-size: 14px;
}

#presented_by {
  margin: 0 20px 0 20px;
  font-family: "Roboto Slab", serif;
  font-size: 10px;
  font-weight: normal;
}

#crumb_list {
  margin-top: 10px;
}

label {
  color: #666;
}

.checkbox label {
  font-weight: bold;
}

body:not(.checkout) div.page-header {
  margin-top: 5px;
}

div.page-header h1 {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 24px;
}

.page-header {
  border-bottom: solid 1px #ddd;
}

div.round {
  border-radius: 2px;
}

.nav-pills li > a {
  padding: 7px 12px;
}

#search_options {
  border-bottom: solid 1px #ccc;
  margin-bottom: 5px;
}

#search_options > table {
  width: 100%;
  margin-bottom: 5px;
  white-space: nowrap;
}

#search_options > table > tbody > tr > td {
  width: 5%;
  padding-right: 20px;
}

.input-xs {
  height: 22px;
  padding: 2px 0;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.page_title h1 {
  display: inline;
  background-color: #999;
  color: #fff;
  height: 22px;
  border-radius: 11px;
  font-size: 16px;
  white-space: nowrap;
  line-height: 20px;
  min-width: 150px;
  padding: 2px 20px 0 20px;
}

.page_title a:link,
.page_title a:hover,
.page_title a:visited {
  color: #fff;
}

.img_novelty {
  float: left;
  width: 60px;
  height: auto;
  margin-right: 8px;
  margin-bottom: 8px;
}

.rr-btn {
  background-color: #666;
  background: linear-gradient(#888, #333);
  border: 1px #666 solid;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  font-family: Meiryo, sans-serif;
  padding: 0 6px;
  display: inline-block;
  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.rr-btn:hover {
  border-color: #ccc;
  color: #eee;
}

a.rr-btn:hover {
  color: #eee;
  text-decoration: none;
}

a.rr-btn:visited,
a.rr-btn:link,
a.rr-btn:visited .fa,
a.rr-btn:link .fa {
  color: #fff;
}

.rr-btn-generic {
  background-color: #69c;
  background: linear-gradient(#8be, #57a);
}

a.rr-btn:disabled,
.rr-btn-disabled {
  background: none;
  color: #999;
  background-color: #ccc;
  border-color: #ccc;
  cursor: default;
}

.rr-btn-disabled:hover {
  color: #999;
  background-color: #ccc;
  border-color: #ccc;
}

.rr-btn-round {
  background-color: #66c;
  background: linear-gradient(#66c, #66c);
  border-radius: 11px;
  height: 20px;
  padding: 3px 10px 0;
}

.rr-btn-generic .fa {
  margin-right: 2px;
}

.rr-btn-info {
  background-color: #1d9bda;
  background: linear-gradient(#3baee7, #0088cc);
  border-color: #137ec6;
  border-top-color: #1992da;
  border-bottom-color: #0d6ab2;
  color: white;
  font-size: 11px;
  padding: 1px 8px;
  line-height: 13px;
  text-align: left;
}

.rr-btn-listen {
  width: 22px;
  height: 17px;
  background-color: #4aa;
  background: linear-gradient(#6cc, #399);
  padding-top: 1px;
  padding-left: 5px;
}

a.rr-btn-listen:visited .fa,
a.rr-btn-listen:link .fa {
  margin: 0;
}

.rr-btn-buy,
.rr-btn-po,
.rr-btn-bo,
.rr-btn-minus,
.rr-btn-listen {
  border-radius: 3px;
  font-size: 11px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.rr-btn-buy,
.rr-btn-po,
.rr-btn-bo {
  width: 28px;
  height: 17px;
  padding-top: 1px;
}

.rr-btn-po {
  color: #fcc;
}

.rr-btn-bo {
  color: #cfc;
}

.rr-btn-minus {
  width: 17px;
  height: 17px;
}

.rr-btn-fixedwidth {
  min-width: 150px;
}

.rr-btn-large {
  font-size: 16px;
  padding: 5px 12px;
  border-radius: 5px;
}

.rr-btn-fixedwidth {
  min-width: 200px;
}

.rr-btn-width-120 {
  min-width: 114px;
}

.rr-btn-width-150 {
  min-width: 150px;
}

.rr-btn-width-180 {
  padding-right: 6px;
  padding-left: 6px;
  min-width: 168px;
}

.rr-btn-width-250 {
  min-width: 250px;
}

.rr-btn-height-30 {
  line-height: 22px;
}

.rr-btn-caption {
  display: inline-block;
  font-size: 11px;
  margin-top: 4px;
}

.frm-warning {
  border: 10px solid #eec;
  font-size: 11px;
  font-weight: bold;
}

#cart_indicator {
  margin: 4px 0;
  background-color: #666;
  background: linear-gradient(#555, #777);
  padding: 1px 10px 2px;
  border-radius: 4px;
  white-space: nowrap;
  font-family: Play, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  text-align: center;
}

#cart_indicator a:visited,
#cart_indicator a:link {
  color: #fff;
}

#header form[name=login_form] {
  margin-top: 5px;
  font-family: Play, sans-serif;
  font-size: 12px;
}

#header form[name=login_form] input {
  font-size: 11px;
}

#footer {
  margin-top: 30px;
  padding-top: 63px;
  background-color: #f3f3f3;
  color: #666;
}

#footer_content {
  padding: 0px 15px;
}

#footer a,
#footer a:link,
#footer a:visited {
  color: #666;
}

#footer .footer_section_title {
  margin: 5px 0;
  font-size: 11px;
  font-weight: bold;
}

#footer_links {
  width: 100%;
  margin-bottom: 20px;
}

#footer_links ul {
  list-style: none;
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

#footer_bottom {
  border-top: solid 1px #ddd;
  padding-top: 4px;
  margin-bottom: 20px;
}

#footer_bottom > div {
  margin: 4px 0;
  font-size: 11px;
}

#footer_bottom .link {
  padding: 0 11px;
  border-right: solid 1px #ddd;
}

.rr-tbl {
  border-collapse: separate;
}

.rr-tbl tr td,
.rr-tbl tr th {
  padding: 1px;
}

.rr-tbl-sns {
  border-collapse: separate;
  border-spacing: 4px;
}

.rr-tbl-sns td {
  padding-right: 4px;
}

td.rr-tbl-colon {
  width: 1%;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: bold;
}

.nopadding tr td {
  padding: 0;
}

.padding-1 tr td {
  padding: 1px;
}

.padding-2 tr td {
  padding: 2px;
}

.padding-3 tr td {
  padding: 2px;
}

.padding-4 tr td {
  padding: 2px;
}

.padding-5 tr td {
  padding: 5px;
}

.padding-6 tr td {
  padding: 5px;
}

.padding-7 tr td {
  padding: 5px;
}

.padding-8 tr td {
  padding: 5px;
}

.padding-9 tr td {
  padding: 5px;
}

.padding-10 tr td {
  padding: 10px;
}

.top_content_title {
  background-color: #777;
  border-radius: 9px 0 0 9px;
  padding-left: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
}

.top_content_title a,
.top_content_title a:link,
.top_content_title a:visited {
  color: #fff;
}

.top_content_title table tr td {
  vertical-align: middle;
}

.top_content_title .more {
  font-size: 10px;
  color: #fff;
  margin-right: 4px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.top_section {
  text-align: left;
  margin-bottom: 20px;
}

.top_section .header {
  white-space: nowrap;
  border-bottom: solid 1px #ccc;
}

.top_section .header .title {
  display: inline;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

.top_section .header a {
  color: #333;
}

.top_section .center {
  background-color: #999;
  border-radius: 12px;
  border-bottom: none;
  margin-bottom: 15px;
}

.top_section .center a {
  font-size: 14px;
  color: #fff;
}

.top_section .header .fa {
  color: #999;
  margin-right: 4px;
}

.top_section .header .rr-btn-more {
  margin-left: 30px;
  font-size: 14px;
}

.top_section .item {
  margin: 10px 0 10px;
}

.top_sub_section {
  float: left;
  width: 48%;
  margin: 0;
  padding: 5px;
}

.top_sub_section .header {
  margin: 20px 0 15px 0;
  border-bottom: none;
}

.top_sub_section .header .title {
  font-size: 14px;
  float: none;
}

.top_sub_section .item {
  float: none;
  padding: 1px;
}

.top_sub_section .item .comment {
  display: block;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  color: #666;
  text-overflow: ellipsis;
  margin: 0 0 15px 0;
}

.top_sub_section .links {
  float: none;
  padding-bottom: 20px;
}

.top_section .item .title {
  color: #000;
  margin: 0;
}

.top_section .rr-row_border {
  clear: both;
  border-bottom: solid 1px #ccc;
}

#description_right {
  float: right;
}

.description {
  width: 100%;
}

.description .section {
  width: 100%;
  border-top: solid 2px #ccc;
  padding-top: 15px;
  padding-bottom: 15px;
}

.item_operations {
  width: 210px;
  margin: 0 auto 5px;
}

.item_operations .btn {
  width: 180px;
  margin-bottom: 10px;
}

.buy_buttons .buy_num {
  height: 17px;
  width: 17px;
  font-size: 11px;
  line-height: 17px;
  padding: 0px;
  text-align: center;
}

#store_selector .label {
  font-family: Lato, sans-serif;
}

#information {
  font-size: 12px;
}

#information .header .fa {
  color: #3baee7;
}

#left_side #information h2 {
  margin: 0;
  width: 100%;
  font-size: 12px;
}

#left_side .header {
  border-bottom: solid 1px #666;
}

#cart_content {
  margin-top: 15px;
  margin-bottom: 38px;
}

#cart_content th, #cart_content td {
  vertical-align: middle;
}

#cart_content thead th {
  text-align: center;
  white-space: nowrap;
}

#cart_content thead th div.checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}

#cart_content .totalPrice {
  text-align: right;
}

#cart_content .totalPrice > td {
  padding-top: 20px;
  padding-bottom: 20px;
}

#cart_content #shipping_config .form-inline {
  margin-top: 20px;
  margin-bottom: 20px;
}

#cart_content #shipping_info {
  padding-bottom: 47px;
}

#shipping_info, #shipping_info_po_bo {
  font-size: 85%;
}

#shipping_info table td, #shipping_info table th, #shipping_info_po_bo table td, #shipping_info_po_bo table th {
  padding: 2px;
  white-space: nowrap;
}

#shipping_info table tbody, #shipping_info_po_bo table tbody {
  border-right: solid 1px #999;
  border-bottom: solid 1px #999;
}

#shipping_info table tbody td, #shipping_info_po_bo table tbody td {
  border-top: solid 1px #999;
  border-left: solid 1px #999;
}

#shipping_info table tbody label, #shipping_info_po_bo table tbody label {
  display: block;
  float: left;
  margin: 0;
  white-space: nowrap;
}

#shipping_info table tbody label span, #shipping_info_po_bo table tbody label span {
  vertical-align: middle;
}

#shipping_info table tfoot td, #shipping_info_po_bo table tfoot td {
  padding-top: 10px;
}

#shipping_info table input, #shipping_info_po_bo table input {
  margin: 0;
  vertical-align: middle;
}

#shipping_info a, #shipping_info a:link, #shipping_info a:visited, #shipping_info_po_bo a, #shipping_info_po_bo a:link, #shipping_info_po_bo a:visited {
  color: #9ff;
}

.tab {
  display: inline-block;
  min-width: 110px;
  background-color: #ccc;
  border-radius: 10px 10px 0 0;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  padding-top: 2px;
  margin-right: 2px;
  color: #fff;
}

.tab.active {
  background-color: #79c;
}

.tab .fa {
  margin-right: 4px;
}

a.tab:link {
  color: #fff;
}

a.tab:visited {
  color: #fff;
}

a.tab:hover {
  text-decoration: none;
  background-color: #79c;
}

.tbl-form {
  font-size: 12px;
  margin-bottom: 50px;
}

.tbl-form .rr-row {
  border-bottom: dotted 1px #333;
}

.tbl-form .label {
  font-weight: bold;
}

.tbl-form .header td {
  background-color: #79c;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
}

.tbl-form .header a:visited,
.tbl-form .header a:link {
  color: #fff;
}

.tbl-form .total {
  border-radius: 4px;
  background-color: #666;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.tbl-form tfoot th {
  border-top: 1px solid #999;
}

.tbl-form tfoot td,
.tbl-form tfoot th {
  padding: 8px 0 8px;
}

.tbl-form.itemlist {
  font-size: 12px;
  margin-bottom: 0;
}

.tbl-form.itemlist .header {
  font-size: 12px;
}

.tbl-form.itemlist .item .header td {
  color: #333;
  background-color: #ccc;
}

.tbl-form.itemlist .item:last-child {
  border-bottom: solid 1px #ccc;
}

.rr-warning {
  font-weight: bold;
  color: #f00;
}

.rr-caution {
  font-weight: bold;
  color: #f60;
}

.rr-info {
  font-weight: bold;
  color: #00c;
}

.rr-small {
  font-size: 10px;
}

.rr-middle {
  font-size: 14px;
}

.rr-large {
  font-size: 18px;
}

.page_navi {
  width: 80%;
  font-family: Play, sans-serif;
  font-size: 14px;
  text-align: center;
}

.page_navi a,
.page_navi span {
  font-weight: bold;
  color: #fff;
  background-color: #79c;
  border-radius: 4px;
  padding: 2px 5px 3px 5px;
  margin: 0;
  line-height: 28px;
}

.page_navi span {
  background-color: #666;
}

.page_navi a.prev,
.page_navi a.next {
  padding-left: 6px;
  padding-right: 6px;
}

.postit {
  background-color: #eef4ff;
  border-right: solid 1px #999999;
  border-bottom: solid 1px #999999;
}

.page_help {
  width: 800px;
  margin: auto;
  margin-bottom: 50px;
}

.page_help .header_help {
  background-color: #79c;
  padding-left: 12px;
}

.page_help .header_help .title {
  font-weight: bold;
  color: #fff;
}

.page_help .title {
  font-weight: bold;
}

.page_help .fa-chevron-right {
  color: #09c;
  margin-left: 10px;
  margin-right: 4px;
}

.page_help a {
  color: #667;
  font-weight: bold;
}

.page_help .bullet_round,
.page_help .fa-circle {
  color: #09c;
}

.header_help .bullet,
.header_help .fa-square {
  color: #cef;
  margin-right: 5px;
}

.tbl_top_item {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
}

.tbl_top_item tr td {
  padding: 2px;
}

.tbl_top_item .bottom_line {
  border-top: solid 1px #c96;
}

.tbl_top_item .item_name {
  font-size: 12px;
}

.buy_buttons tr td {
  padding: 0px;
}

#order_history > tbody > tr > td {
  border-top: none;
  border-bottom: solid 1px #ddd;
}

#search_description .narrow_down span {
  white-space: nowrap;
  padding-right: 0.6em;
}

#btn-to_detailed_search {
  font-size: 10px !important;
  line-height: 11px !important;
  margin: 0;
}

#btn-to_simple_search {
  font-size: 10px;
}

.content-cart .recommendations {
  margin-top: 8px;
}

.content-cart .recommendations .item {
  position: relative;
  padding: 0 25px;
  vertical-align: top;
  text-align: center;
}

.content-cart .recommendations .item .rank {
  position: absolute;
  font-family: Lato, sans-serif;
  font-size: 12px;
  top: 8px;
  left: 33px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #fff;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  background-color: #00f;
}

.content-cart .recommendations .item .clamp {
  margin-top: 2px;
  margin-bottom: 2px;
}

.share_buttons {
  margin: 18px auto 0;
  text-align: center;
  font-family: Arial sans-serif;
}

.share_buttons .icon {
  color: #fff !important;
  width: 32px;
  height: 32px;
  font-family: FontAwesome;
  font-size: 18px;
  line-height: 32px;
  border: 0;
  border-radius: 3px;
  margin: 0;
}

.share_buttons .facebook {
  background-color: #3b5998;
}

.share_buttons .facebook:after {
  content: "\f09a";
}

.share_buttons .twitter {
  background-color: #55acee;
}

.share_buttons .twitter:after {
  content: "\f099";
}

.share_buttons .instagram {
  background: linear-gradient(220deg, #e0f, #f5f800);
}

.share_buttons .instagram:after {
  content: "\f16d";
}

.share_buttons .email {
  background-color: #e60;
}

.share_buttons .email:after {
  content: "\f0e0";
}

.content-inventory .page-header {
  margin-bottom: 15px;
}

.content-inventory .list_options {
  padding-bottom: 15px;
  border-bottom: solid #ccc 1px;
}

/* Legacy */
.menu11 {
  font-size: 11px;
  color: #000000;
}

.txt9 {
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 9px;
  font-weight: 300;
  color: #000000;
}

.headtitle {
  font-family: "Arial", "Helvetica", "sans-serif";
  font-size: 10px;
  font-weight: 300;
  color: #FFFFFF;
}

.headtitle2 {
  font-size: 10px;
  color: #FFFFFF;
  font-weight: 100;
  line-height: 10px;
  margin: 0 0 6px 10px;
}

.txt10 {
  font-size: 10px;
  color: #333333;
  font-weight: 100;
}

.txt10_white {
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  font-weight: 100;
}

.txt10_bwhite {
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  font-weight: bold;
}

.txt12 {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: #333333;
}

.txt_help {
  font-size: 14px;
  font-weight: normal;
  line-height: 28px;
  color: #333333;
}

.txt14 {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  line-height: 20px;
}

.txt12_bwhite {
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
}

.txt12_table {
  font-size: 12px;
  color: #000000;
  line-height: 14px;
}

.txt10_b993300 {
  font-size: 10px;
  font-weight: bold;
  color: #993300;
}

.txt12_white {
  font-size: 12px;
  color: #FFFFFF;
  font-weight: normal;
}

.txt11_white {
  font-size: 11px;
  line-height: 12px;
  color: #FFFFFF;
  font-weight: normal;
}

.txt11 {
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  font-weight: normal;
}

.txt10y {
  font-size: 10px;
  color: #333333;
  font-weight: 100;
  background-color: #FFFFCC;
}

.txt11y {
  font-size: 10px;
  color: #333333;
  font-weight: 100;
  background-color: #FFFFCC;
}

.txt11b {
  font-size: 11px;
  color: #000000;
  font-weight: normal;
  padding: 2px;
  background-color: #cff;
}

.txt16 {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}

.txt20 {
  font-size: 20px;
  font-weight: normal;
  color: #333333;
}

.txt12_bt {
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
}

.txt10i {
  font-size: 10px;
  color: #333333;
  font-weight: 100;
  line-height: 10px;
}

.txt10fix {
  font-family: "Courier New", "Courier", "mono";
  font-size: 11px;
  height: 15px;
  width: 19px;
  color: #000000;
  font-weight: bold;
  background-color: #FFFFFF;
  padding-top: 1px;
  text-align: center;
}

div.scroll {
  position: absolute;
  visibility: hidden;
  width: 180px;
  height: 320px;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 4px;
  background-color: #CCFFFF;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 100;
  font-size: 11px;
  line-height: 12px;
  color: #000000;
}

.left-banner {
  margin: 8px 3px 8px 3px;
  padding: 3px 3px 16px 3px;
  background-color: #FFFFFF;
}

.left-banner-item {
  margin: 16px 0 0 0;
  text-align: center;
}

.left-banner-photo {
  margin: 0;
  border: 1px solid #666666;
}

.left-banner-title {
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  background-color: #CCCCCC;
  text-align: center;
}

a.menu11:link {
  color: #000000;
  text-decoration: none;
}

a.menu11:visited {
  color: #000000;
  text-decoration: none;
}

a.menu11:active {
  color: #000000;
  text-decoration: none;
}

a.menu11:hover {
  color: #FFFFFF;
  background-color: #000066;
  text-decoration: none;
}

img,
fieldset {
  border: 0;
}

/*
row-height css
http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-columns-of-same-height
*/
.inside {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ededed;
  background: -ms-linear-gradient(top, #f4f4f4 0%, #ededed 100%);
}

.inside-full-height {
  /*
  // if you want to give content full height give him height: 100%;
  // with content full height you can't apply margins to the content
  // content full height does not work in ie http://stackoverflow.com/questions/27384433/ie-display-table-cell-child-ignores-height-100
  */
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

/* columns of same height styles */
.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}

.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 480px) {
  .row-xs-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-xs-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-xs-top {
    vertical-align: top;
  }
  .col-xs-middle {
    vertical-align: middle;
  }
  .col-xs-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 768px) {
  .row-sm-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-sm-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-sm-top {
    vertical-align: top;
  }
  .col-sm-middle {
    vertical-align: middle;
  }
  .col-sm-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 992px) {
  .row-md-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-md-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-md-top {
    vertical-align: top;
  }
  .col-md-middle {
    vertical-align: middle;
  }
  .col-md-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1200px) {
  .row-lg-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-lg-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-lg-top {
    vertical-align: top;
  }
  .col-lg-middle {
    vertical-align: middle;
  }
  .col-lg-bottom {
    vertical-align: bottom;
  }
}

/*# sourceMappingURL=app.css.map */
